<template>
    <modal
        transition="nice-modal-fade"
        class="new-modal-default"
        :name="modal_name"
        :delay="100"
        height="auto"
        width="350"
        :adaptive="true"
        :scrollable="false"
    >
        <div class="v-modal-content p-4" style="background-color: #dfe8f4 !important;">
            <div class="d-flex justify-content-center">
                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/snake.svg" class="p-3" style="height: 6rem !important" alt="">
            </div>
            <div class="py-2 text-center">
                <h3 class="text-center fs-20 fw-normal">Sorry!</h3>
                <span class="fs-13 fw-normal py-2 text-center" style="">
                    {{error_reason}}
                </span>
            </div>
            <div class="footer mt-3 d-flex justify-content-center">
                <button class="btn close-btn px-5 fs-14 text-center" @click="hideSubmitFailedModal()">Close</button>
            </div>
        </div>
    </modal>
</template>

<script>

export default({
    props:['modal_name', 'error_reason'],
    methods: {
        hideSubmitFailedModal(){
            this.$emit('hide-submit-failed-modal')
        }
    }
})

</script>

<style scoped>
    .close-btn{
        background: #009cd2;
    }
</style>
